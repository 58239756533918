import React from 'react'
import './ArchivedQualifications.scss'
import Images from '../../assets/images'
import useGetQualifications from "../../hooks/useGetQualifications"
import QualificationCard from "../QualificationCard"
import {useNavigate} from "react-router-dom"
import Qualification from "../../interfaces/qualifications";

const ArchivedQualifications: React.FC = () => {

    const {userQualifications, setUserQualifications} = useGetQualifications()

    const archivedQualification: Qualification[] = [];
    if (userQualifications) {
        for (const qualification of userQualifications) {
            if (qualification.archived) {
                archivedQualification.push(qualification)
            }
        }
    }
    const navigate = useNavigate()
    const handleAddQualification = () => {
        navigate('/add-qualifications')
    }
    const handleDeleteQualification = (qualificationId: number | null) => {
        setUserQualifications(prevUserQualifications => {
            if (prevUserQualifications) {
                return prevUserQualifications.filter(link => link.id !== qualificationId)
            }
            return []
        })
    }

    return (
        <div className='page homepage archived-qualifications'>
            <div className='greetings'>
                Hey{userQualifications?.length && userQualifications.length > 0 ? ', ' + userQualifications[0].user.first_name : ''}
            </div>
            <div className='your-qualifications'>
                <img
                    src={Images.verifiedCheck}
                    className='verified-check icon-subtitle'
                    alt=""
                />
                <div className='qualifications-subtitle'>
                    Your Archived Qualifications
                </div>
            </div>
            {archivedQualification?.length && archivedQualification.length > 0 ? (
                archivedQualification.map((qualification, index) => (
                    <div
                        className='qualifications'
                        key={index}
                    >
                        <QualificationCard
                            qualification={archivedQualification[index]}
                            onDelete={() => handleDeleteQualification(archivedQualification[index].id)}
                        />
                    </div>
                ))
            ) : (
                <div className='qualification-card empty-state'>
                    <div className='qualification empty-state-text'>
                        <div className='qualification-title empty-state-title'>
                            No Archived Qualifications
                        </div>
                        <div className='qualification-type empty-state-subtitle'>
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            When you archive a qualification it will appear here
                        </div>
                    </div>
                    <div className='line'/>
                    <button
                        className='button add-qualification'
                        onClick={handleAddQualification}
                    >
                        + Add Qualification
                    </button>
                </div>
            )}
        </div>
    )
}

export default ArchivedQualifications
