import React from 'react'
import './SharedQualification.scss'
import Images from '../../assets/images'
import QualificationCard from "../QualificationCard"
import {useParams} from 'react-router';
import useGetSharedQualifications from "../../hooks/useGetSharedQualification";

const SharedQualification: React.FC = () => {
    const {id} = useParams();
    const {qualifications} = useGetSharedQualifications(id)
    let name = 'User'
    if (qualifications) {
        name = qualifications[0]?.user?.first_name || "User";
        for (const qualification of qualifications) {
            qualification.shared = true;
        }
    }

    const handleDeleteQualification = () => {
        console.log('Tried to delete');
    }


    return (
        <div className='page sharedPage'>
            <div className='greetings'>
                {name}&apos;s shared qualification
            </div>
            <div className='your-qualifications'>
                <img
                    src={Images.whiteVerifiedCheck}
                    className='verified-check icon-subtitle'
                    alt=""
                />
                <div className='qualifications-subtitle'>
                    Your Qualifications
                </div>
            </div>

            {qualifications?.length && qualifications.length > 0 ? (
                qualifications.map((qualification) => (
                    <div
                        className='qualifications'
                        key={0}
                    >
                        <QualificationCard
                            qualification={qualification}
                            onDelete={() => handleDeleteQualification()}
                        />
                    </div>
                ))
            ) : (
                <div className='qualification-card empty-state'>
                    <div className='qualification empty-state-text'>
                        <div className='qualification-title empty-state-title'>
                            Sorry No Qualification Found
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SharedQualification
