import React, {useState} from "react";
import "./ResetPassword.scss";
import Images from "../../assets/images";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

interface ResetPasswordFormState {
    confirm_password: string;
    password: string;
}

const ResetPassword: React.FC = () => {
    const {token} = useParams()

    const [resetEmail] = useState<string>("steven@layers.studio1") //need to obtain this email

    const [resetPasswordFormData, setResetPasswordFormData] =
        useState<ResetPasswordFormState>({
            password: "",
            confirm_password: "",
        });

    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] =
        useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        setPasswordError("");
        setConfirmPasswordError("");

        if (name === "password") {
            if (!value || value.length < 8) {
                setPasswordError(
                    "Password must be at least 8 characters long."
                );
            } else if (!/(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(value)) {
                setPasswordError(
                    "Password must contain at least one capital letter, one number, and one special character."
                );
            }
        } else if (name === 'confirm_password') {
            if (value !== resetPasswordFormData.password) {
                setConfirmPasswordError('Passwords do not match.')
            } else {
                setConfirmPasswordError('')
            }
        }

        setResetPasswordFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (confirmPasswordError || passwordError) {
            return;
        }
        try {
            await axios.post(
                `https://biblio.nebulalabs.cc/api/user/reset-password/${token}/`,
                {
                    password: resetPasswordFormData.password,
                    passwordConfirm: resetPasswordFormData.confirm_password,
                    token_id: token
                }
            );
            alert("Password reset successful!")
        } catch (error) {
            alert("Server error.");
        }
    };

    return (
        <div className="page-authentication reset-pass-page">
            <img src={Images.biblioLogo} className="logo" alt=""/>
            <div className="reset-pass-form">
                <div className="form-title" data-testid="reset-password-title">Reset Password</div>
                <div className="line reset-pass"/>
                <div className="email-password">
                    <div className="first-form-subtitle">
                        Please create and confirm your new password associated with the email address: {resetEmail}
                    </div>
                    <div className="input-title password">New Password</div>
                    <input
                        type="password"
                        className="custom-input"
                        onChange={handleChange}
                        value={resetPasswordFormData.password}
                        name="password"
                        data-testid="password-input"
                    />
                    {passwordError && (
                        <div className="error-message">{passwordError}</div>
                    )}
                    <div className="input-title password">Confirm New Password</div>
                    <input
                        type="password"
                        className="custom-input"
                        onChange={handleChange}
                        value={resetPasswordFormData.confirm_password}
                        name="confirm_password"
                        data-testid="confirm-password-input"
                    />
                    {confirmPasswordError && (
                        <div className="error-message">{confirmPasswordError}</div>
                    )}
                </div>
                <div className="line"/>
                <div className="reset-pass-buttons">
                    <button className="button reset-pass" onClick={handleSubmit} data-testid="reset-password-button">
                        Reset Password
                    </button>
                    <Link to="/" className="back">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Doesn't matter, return to Sign In
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
