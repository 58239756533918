import React from 'react'
import './HomePage.scss'
import Images from '../../assets/images'
import useGetQualifications from "../../hooks/useGetQualifications"
import QualificationCard from "../QualificationCard"
import {useNavigate} from "react-router-dom"
import Qualification from "../../interfaces/qualifications";

const HomePage: React.FC = () => {
    const {userQualifications, setUserQualifications} = useGetQualifications()

    const unArchivedQualification: Qualification[] = [];
    if (userQualifications) {
        for (const qualification of userQualifications) {
            if (!qualification.archived) {
                unArchivedQualification.push(qualification)
            }
        }
    }

    const navigate = useNavigate()

    const handleDeleteQualification = (qualificationId: number | null) => {
        setUserQualifications(prevUserQualifications => {
            if (prevUserQualifications) {
                return prevUserQualifications.filter(link => link.id !== qualificationId)
            }
            return []
        })
    }
    const handleAddQualification = () => {
        navigate('/add-qualifications')
    }

    return (
        <div className='page homepage'>
            <div className='greetings'>
                Hey{userQualifications?.length && userQualifications.length > 0 ? ', ' + userQualifications[0].user.first_name : ''}
            </div>
            <div className='your-qualifications'>
                <img
                    src={Images.whiteVerifiedCheck}
                    className='verified-check icon-subtitle'
                    alt=""
                />
                <div className='qualifications-subtitle'>
                    Your Qualifications
                </div>
            </div>
            {unArchivedQualification?.length && unArchivedQualification.length > 0 ? (

                unArchivedQualification.map((qualification, index) => (
                    <div className='qualifications'
                         key={index}>
                        <QualificationCard
                            qualification={unArchivedQualification[index]}
                            onDelete={() => handleDeleteQualification(unArchivedQualification[index].id)}
                        />
                    </div>
                ))

            ) : (
                <div className='qualification-card empty-state'>
                    <div className='qualification empty-state-text'>
                        <div className='qualification-title empty-state-title'>
                            Get Started
                        </div>
                        <div className='qualification-type empty-state-subtitle'>
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            It's currently empty in here, go ahead and add
                            your first Qualification.
                        </div>
                    </div>
                    <div className='line'/>
                    <button
                        className='button add-qualification'
                        onClick={handleAddQualification}
                    >
                        + Add Qualification
                    </button>
                </div>
            )}
        </div>
    )
}

export default HomePage
