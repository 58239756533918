import React from 'react'
import './ManualHandling.scss'
import Images from "../../assets/images"
import {useNavigate} from 'react-router-dom'
import useAuthorizationInterceptor from "../../hooks/useAuthorizationInterceptor"
import useGetQualification from "../../hooks/useGetQualification";

interface ManualHandlingProps {
    isOpen: boolean,
    onClose: () => void,
    qualificationId: number | null,
    onDelete: (qualificationId: number | null) => void
}

const ManualHandling: React.FC<ManualHandlingProps> = ({isOpen, onClose, qualificationId, onDelete}) => {
    const {qualification} = useGetQualification(qualificationId)
    const navigate = useNavigate();
    const api = useAuthorizationInterceptor();
    const handleEditQualification = () => {
        onClose()
        if (qualificationId !== null) {
            navigate(`/edit-qualification/${qualificationId}`)
        }
    }

    const handleArchivedQualifications = async () => {
        if (qualificationId) {
            await api.patch(
                `/qualifications/${qualificationId}/`,
                {archived: true}
            ).catch((err) => {
                console.error('Error archiving qualification:', err)
            })
            localStorage.setItem('activeLink', '/settings')
            navigate('/archived-qualifications')
            onDelete(qualificationId)
        }
        onClose()
    }
    const handleUnArchiveQualification = async () => {
        if (qualificationId) {
            await api.patch(
                `/qualifications/${qualificationId}/`,
                {archived: false}
            ).catch(function (error) {
                console.log('Error unArchiving ', error)
            })

            onDelete(qualificationId)
            localStorage.setItem('activeLink', '/settings')
            navigate('/archived-qualifications')
        }
        onClose()
    }

    const deleteQualification = async () => {
        try {
            await api.delete(
                `/qualifications/${qualificationId}/`
            ).catch(function (error) {
                console.error('Error deleting qualification:', error)
            })
            onDelete(qualificationId)
            onClose()
        } catch (error) {
            console.error('Error deleting qualification:', error)
            alert('An error occurred while deleting the qualification.')
        }
    }

    const manualHandlingModal = isOpen ? 'modal-overlay open' : 'modal-overlay';
    if (!isOpen) {
        document.body.style.overflow = 'unset';
        return null
    } else {
        document.body.style.overflow = 'hidden';
    }


    return (
        <div className={manualHandlingModal} onClick={onClose} data-testid="modal-overlay">
            <div className='modal-content manual-handling' onClick={(e) => e.stopPropagation()}>
                <div className='modal-header'>
                    <div className='modal-header-title'>
                        Manual Handling
                    </div>
                    <img
                        className='modal-close-button'
                        src={Images.closeButton}
                        onClick={onClose}
                        alt=""
                    />
                </div>
                <div className='actions-buttons'>
                    {qualification?.archived ? (
                        <button
                            className='button archive'
                            onClick={handleUnArchiveQualification}
                        >
                            Un-Archive Qualification
                        </button>
                    ) : (
                        <>
                            <button
                                className='button edit'
                                onClick={() => handleEditQualification()}
                            >
                                Edit Qualification
                            </button>
                            <button
                                className='button archive'
                                onClick={handleArchivedQualifications}
                            >
                                Archive Qualification
                            </button>
                        </>
                    )}

                    <button
                        className='button delete'
                        onClick={deleteQualification}
                    >
                        Delete Qualification
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ManualHandling

