import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {useEffect, useState} from 'react'

type AxiosHeaders = {
    [key: string]: string
}

const api = axios.create({
    baseURL: 'https://biblio.nebulalabs.cc/api'
})

const useAuthorizationInterceptor = () => {
    const [tokenRefreshing] = useState(false)


    const applyHeaders = <T>(config: AxiosRequestConfig) => {
        const token = localStorage.getItem('access')
        if (token) {
            // Remove quotes from token
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`
            } as AxiosHeaders
        }
        return config as AxiosRequestConfig & T
    }

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            async (config: AxiosRequestConfig) => applyHeaders(config),
            (error: AxiosError) => Promise.reject(error)
        )

        const responseInterceptor = api.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error: AxiosError) => {
                return Promise.reject(error)
            }
        )

        return () => {
            api.interceptors.request.eject(requestInterceptor)
            api.interceptors.response.eject(responseInterceptor)
        }
    }, [tokenRefreshing])

    return api
}

export default useAuthorizationInterceptor
