import {useEffect, useState} from 'react'
import Qualification from "../interfaces/qualifications"
import useAuthorizationInterceptor from "./useAuthorizationInterceptor";

const useGetQualification = (id: null | number) => {
    const [qualification, setQualification] = useState<Qualification | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const api = useAuthorizationInterceptor()

    const fetchQualification = async () => {
        try {
            if (id) {
                await api.get('/qualifications/' + id + '/').then((response) => {
                    setQualification(response.data.data)
                }).catch((err) => {
                    setError(err)
                    setQualification(null)
                });
            } else {
                setQualification(null)
            }
            setLoading(false)
        } catch (errorResponse) {
            if (typeof errorResponse === 'string') {
                setError(errorResponse)
            } else {
                setError("An error occurred.")
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchQualification()
    }, [])

    return {qualification, loading, error, setQualification}
}

export default useGetQualification
